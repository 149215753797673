/**
 * 验证邮箱规则(输入框提示)
 */
import {getIsExistEmailOrPhone} from "../server/auth";
import vue from '../main'

export const checkEmail=(rule,value,cb)=>{
    // const regEmail=/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    const regEmail=/^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/
    if (regEmail.test(value)) return cb()
    cb(new Error(vue.$t('validate.check_email')))
}

export const checkWithdrawalAddress=(rule,value,cb)=>{
    if (value.substr(0,1) == 'T' && value.length==34) return cb()
    cb(new Error(vue.$t('validate.withdrawal_address_incorrect')))
}

/**
 * 验证手机号规则(输入框提示)
 */
export const checkMobile=(rule,value,cb)=>{
    const regMobile=/^[1]([3-9])[0-9]{9}$/
    if (regMobile.test(value)) return cb()
    cb(new Error(vue.$t('validate.check_phone')))
}

/**
 * 验证密码(输入框提示)
 */
export const checkPassword=(rule,value,cb)=>{
    const regMobile=/^(?=.*[A-Z])(?=.*\d).{8,}$/
    if (regMobile.test(value)) return cb()
    cb(new Error(vue.$t('validate.check_password')))
}


/**
 * 验证邮箱规则(通用)
 */
export const checkEmailGeneral=(value)=>{
    // const regEmail=/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    const regEmail=/^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/
    if (regEmail.test(value)) return true
    return false
}
/**
 * 验证手机号规则(通用)
 */
export const checkMobileGeneral=(value)=>{
    const regMobile=/^[1]([3-9])[0-9]{9}$/
    if (regMobile.test(value)) return true
    return false
}
/**
 * 验证两次输入密码是否一致(通用)
 */
export const checkNewPassword=(value,newPassword)=>{
    if (value===newPassword) return true
    return false
}

/**
 * 验证邮箱规则和是否存在(输入框提示)
 */
export const checkEmailOrIsExist=(rule,value,cb)=>{
    // const regEmail=/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    const regEmail=/^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/
    if (regEmail.test(value)) {
        getIsExistEmailOrPhone(1,value).then(res=>{
            if (res.success===false) return cb(new Error(vue.$t('login.email_already_exists')))
            return cb()
        })
    }
    else cb(new Error(vue.$t('validate.check_email')))
}

/**
 * 验证手机号规则和是否存在(输入框提示)
 */
export const checkMobileOrIsExist=(rule,value,cb)=>{
    const regMobile=/^[1]([3-9])[0-9]{9}$/
    if (regMobile.test(value)) {
        getIsExistEmailOrPhone(2,value).then(res=>{
            if (res.success===false) return cb(new Error(vue.$t('login.mobile_already_exists')))
            return cb()
        })
    }
    else cb(new Error(vue.$t('validate.check_phone')))
}
/**
 * 验证是否输入的是表情
 */
export const checkIsEmoji=(rule,value,cb)=>{
    let isExist= isEmojiCharacter(value)
    if (isExist!==undefined) cb(new Error(vue.$t('validate.not_character')))
        return cb()
}
/**
 * 验证是否输入的是表情(通用)
 */
export const checkIsEmojiGeneral=(value)=>{
    let isExist= isEmojiCharacter(value)
    if (isExist!==undefined) return false
    return true
}
function isEmojiCharacter(substring) {
    for ( var i = 0; i < substring.length; i++) {
        var hs = substring.charCodeAt(i);
        if (0xd800 <= hs && hs <= 0xdbff) {
            if (substring.length > 1) {
                var ls = substring.charCodeAt(i + 1);
                var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;
                if (0x1d000 <= uc && uc <= 0x1f77f) {
                    return true;
                }
            }
        } else if (substring.length > 1) {
            var ls = substring.charCodeAt(i + 1);
            if (ls == 0x20e3) {
                return true;
            }
        } else {
            if (0x2100 <= hs && hs <= 0x27ff) {
                return true;
            } else if (0x2B05 <= hs && hs <= 0x2b07) {
                return true;
            } else if (0x2934 <= hs && hs <= 0x2935) {
                return true;
            } else if (0x3297 <= hs && hs <= 0x3299) {
                return true;
            } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030
                || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b
                || hs == 0x2b50) {
                return true;
            }
        }
    }
}

/**
 * 判断屏幕大小变化的函数
 */
export default function isScreenSize(size){
    window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        return bodyWidth >= size;
    })
        const bodyWidth=document.body.clientWidth
        return bodyWidth > size;

}
/**
 * 驗證只能輸入精確到小數點後兩位
 */
export const checkTwoPlace=(rule,value,cb)=>{
    value=value.toString()
    if (value.indexOf('.')===-1) return cb()
    if (value.slice(value.indexOf('.')).length-1>2) return cb(new Error(vue.$t('validate.decimal_two')))
    return cb()
}

export const checkTwoPlaceGeneral=(value)=>{
    value=value.toString()
    if (value.indexOf('.')===-1) return true
    if (value.slice(value.indexOf('.')).length-1>2) return false
    return true
}

// export const splitNum=(str) =>{
//     if (!str) {
//         return '';
//     }
//     const strArr = (str + '').split('').reverse().join('').replace(/(\d{3})(?=\d)/g, '$1,')
//         .split('')
//         .reverse();
//     return strArr.join('');
// }


filters:{
    const splitNum=(value) =>{
        console.log(value);
        let timeArr=value.split('T');
        return timeArr[0];
    }
}
